/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* --------------------------------------------------------- */
html,
body,
#root,
.full-height {
  /* min-height: 100%;
  min-width: 100%;
  width: 100%; */
  /* height: 100%; */
  margin: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
input,
form,
button,
p {
  font-family: "Sen", sans-serif;
  font-family: "Rubik", sans-serif !important;
  font-weight: 300;
  color: black !important;
}

div {
  font-family: "Sen", sans-serif;
}

h1 {
  font-size: 2em;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 5px;
}

h2 {
  font-size: 1.6em;
}
h3 {
  font-size: 1.3em;
}

em {
  font-size: 2em;
  font-style: normal;
}

p {
  font-size: 1em;
}

b {
  font-weight: 700;
}

form {
  font-size: 1em;
}

input {
  /* font-size: 1.5em !important; */
}

input {
  font-family: "Caveat", cursive !important;
  font-size: 4em !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  /* color: white; */
  font-style: italic;
}
::-moz-placeholder {
  /* Firefox 19+ */
  /* color: white; */
  font-style: italic;
}
:-ms-input-placeholder {
  /* IE 10+ */
  /* color: white; */
  font-style: italic;
}
:-moz-placeholder {
  /* Firefox 18- */
  /* color: white; */
  font-style: italic;
}

/* button,
.button {
  border: none;
  padding: 10px;
  color: white;
  background: linear-gradient(to bottom right, #d62346, #f0aa71);
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  margin-right: 5px;
  margin-bottom: 5px;
} */

.aws-btn {
  --slider-height-percentage: 100%;
  --slider-transition-duration: 700ms;
  --organic-arrow-thickness: 6px;
  --organic-arrow-border-radius: 25px;
  --organic-arrow-height: 40px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #2d5182;
  --control-bullet-active-color: #26456f;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}

.organic-arrow {
  color: #ff0da5;
}

.awssld {
  --organic-arrow-color: #d62346 !important;
  --content-background-color: rgba(0, 0, 0, 0) !important;
}

.awssld__controls button {
  background: none !important;
  box-shadow: none !important;
}

.awssld__content {
  align-items: flex-start !important;
  /* height */
}

.awssld__box {
  /* height: 0; */
}

.awssld__container {
  height: 100vh !important;
}

.carousel,
.carousel__slider--horizontal {
  height: 100% !important;
  overflow: scroll !important;
}

.carousel__slider-tray-wrap--horizontal {
  overflow-x: hidden;
}

/* -----MAterial UI ------- */

.MuiInputLabel-animated {
  color: white !important;
  font-family: "Rubik", sans-serif !important;
}

.MuiInput-underline,
.MuiInput-underline:before {
  border-color: white !important;
}

.MuiInputBase-input,
.MuiInput-input {
  color: white !important;
  font-family: "Rubik", sans-serif !important;
  font-size: 1.5em;
}

.MuiFormControl-root {
  margin-left: auto !important;
  margin-right: auto !important;
}

.checkin-toast {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  height: auto !important;
  width: auto !important;
}

.Toastify__toast-body {
  display: flex !important;
  justify-content: flex-end !important;
}

.Toastify__toast {
  padding: 2px !important;
  margin-bottom: 2px !important;
}

.Toastify__close-button {
  display: none;
}

fieldset {
  text-align: center !important;
}

.card-deck {
  _position: absolute;
  position: relative;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  _display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.card-deck__card-outer,
.card-deck__card-inner {
  /* height: 414px; 300*(1121/821) + 2*padding */
  width: 304px;
}

.card-deck__card-outer {
  position: absolute;
}

.card-deck__card-inner {
  /* padding: 2px; */
  background-color: white;
  position: relative;
  will-change: transform;
  border-radius: 30px;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  /* box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3); */
}

.card-deck__card-inner img {
  border-radius: 10px;
  height: 410px;
  pointer-events: none;
  width: 300px;
}

.back-face {
  left: 2px;
  position: absolute;
  top: 2px;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  z-index: -1;
}

