.card-deck {
  _position: absolute;
  position: relative;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  _display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.card-deck__card-outer,
.card-deck__card-inner {
  /* height: 414px; 300*(1121/821) + 2*padding */
  width: 304px;
}

.card-deck__card-outer {
  position: absolute;
}

.card-deck__card-inner {
  /* padding: 2px; */
  background-color: white;
  position: relative;
  will-change: transform;
  border-radius: 30px;
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  /* box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3); */
}

.card-deck__card-inner img {
  border-radius: 10px;
  height: 410px;
  pointer-events: none;
  width: 300px;
}

.back-face {
  left: 2px;
  position: absolute;
  top: 2px;
  transform: rotateY(-180deg);
  z-index: -1;
}
